.sidebar{
    &.sidebar-mini {
        + {
            .main-content {
                transition: all 300ms ease;
                margin-right: 4.8rem;
                margin-left: unset;
            }
        }
        &.sidebar-hover{
            &.sidebar-transparent{
                &:hover{
                    +.main-content{
                        margin-right: 16.2rem;
                        margin-left: unset;
                    }
                }
            }
        }
    }
}
.sidebar-hover{
    &:hover{
        .sidebar-list{
            .static-item{
                text-align: right;
            }
        }
    }
}
@include media-breakpoint-down(xl) {
    .sidebar {
        &.sidebar-default {
            &.sidebar-mini {
                transform: translateX(100%);
                .sidebar-header {
                    a.navbar-brand {
                        transform: translate(100%);
                    }
                }
            }
        }
    }
    .sidebar {
        &.sidebar-default.sidebar-mini+.main-content{
            margin-right: 0;
            margin-left: unset;
        }
    }
}

@include media-breakpoint-down(xl) {
    .sidebar {
        .sidebar-toggle {
            left: 18px;
        }
    }
}

.sidebar-default{
    .sidebar-body{
        padding-left: 1rem;
        padding-right: unset;
    }
    &.sidebar-mini{
        .sidebar-body{
            padding-right: 1rem;
        }
    }
    &.navs-pill, &.navs-rounded{
        .sidebar-body{
            padding-right: unset;
        }
    }
}

.sidebar-hover:hover.navs-rounded-all .navbar-nav .nav-item:not(.static-item), 
.sidebar-hover:hover.navs-pill-all .navbar-nav .nav-item:not(.static-item){
    padding-left: unset;
}

.sidebar-mini{
    .sidebar-list{
        .navbar-nav{
            .nav-item{
                .nav-link{
                    &:not(.disabled){
                        span{
                            transform: translateX(100%) scale(0);
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
}

.sidebar-mini{
    .navbar-brand {
        .logo-title {
            transform: translateX(100%) scale(0);
            opacity: 0;
        }
    }
}
.sidebar-hover {
    &:hover {
        .logo-title {
            transform: translateX(0%);
            opacity: 1;
        }
        .sidebar-list{
            .navbar-nav{
                .nav-item{
                    .nav-link{
                        &:not(.disabled){
                            span{
                                transform: translateX(0%);
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}