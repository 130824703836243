.iq-chat-tab.nav-tabs{
    .nav-link{
        color: unset;
        padding: unset;
        &.active, &:hover{
            color: var(--#{$variable-prefix}primary);
            background: unset;
            border: unset;
        }
    }
}
.iq-profile-badge{
    border-color: $card-bg;
}
.iq-chating-content, .chat-day-title > .main-title{
    background-color: $card-bg;
    border-color: $border-color;
    color: $white;
}
.iq-chating {
    color: $white;
}
.sidebar-default{
    .navbar-nav{
        .nav-item{
            &.iq-chat-list{
                .iq-userlist-data{
                    .iq-userlist-name{
                        color: $white;
                    }
                }
            }
        }
    }
}
