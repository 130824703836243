:focus{
	outline: none;
}
h1, h2, h3, h4, h5, h6 {
    font-family: var(--#{$variable-prefix}heading-font-family);
}
a{
    transition: color 400ms ease, background 400ms ease;
}
.iq-slider{
    overflow: hidden;
    overflow-x: auto;
}
.iq-scroller-effect{
    ::-webkit-scrollbar { width: 5px; height: 5px; border-radius: 20px; }

    /* Scroll Track */
    ::-webkit-scrollbar-track { background: $body-bg; border-radius: 20px; }
    
    /* Scroll Handle */
    ::-webkit-scrollbar-thumb { background: $border-color; border-radius: 20px; transition: all 400ms ease; cursor: pointer; }
    
    /* Scroll Handle on hover */
    ::-webkit-scrollbar-thumb:hover { background: tint-color($border-color, 10%); border-radius: 20px;transition: all 400ms ease }
}