.showing {
    transition-timing-function: ease-in;

    /* Quick on the way out */
    transition: 0.2s;

    /* Hide thing by pushing it outside by default */
    transform: translateY(130%);
}

.shown {
    /* This timing applies on the way IN */
    transition-timing-function: ease-out;

    /* A litttttle slower on the way in */
    transition: 0.25s;

    /* Move into place */
    transform: translateY(0);
}