.boarder-start{
    border-left-color:rgba($white,.1) !important;
}
.border-end {
    border-right-color: rgba($white,.1) !important;
}
.border-top{
    border-top-color: rgba($white,.1) !important;
}
.border-bottom{
    border-bottom-color: rgba($white,.1) !important;
}