.iq-custom-badge{
    margin-left: 1rem;
    margin-right: unset;
    &:last-child{
        margin-left: 0;
        margin-right: unset;
    }
    .iq-cancel-btn{
        left: -0.5rem;
        right: unset;
    }
}