.text-ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    &.short-1{
        -webkit-line-clamp: 1;
    }
    &.short-2{
        -webkit-line-clamp: 2;
    }
    &.short-3{
        -webkit-line-clamp: 3;
    }
    &.short-4{
        -webkit-line-clamp: 4;
    }
    &.short-5{
        -webkit-line-clamp: 5;
    }
    &.short-6{
        -webkit-line-clamp: 6;
    }
    &.short-7{
        -webkit-line-clamp: 7;
    }
    &.short-8{
        -webkit-line-clamp: 8;
    }
    &.short-9{
        -webkit-line-clamp: 9;
    }
    &.short-10{
        -webkit-line-clamp: 10;
    }
}