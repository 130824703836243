.shepherd-has-title {
	.shepherd-content {
		.shepherd-header {
			background: $card-bg;
		}
	}
}
.shepherd-element.shepherd-has-title[data-popper-placement^=bottom] {
	>.shepherd-arrow {
		&:before {
			background-color: $card-bg;
			border-left: 1px solid $card-bg;
			border-top: 1px solid $card-bg;
		}
	}
}
.shepherd-content {
	background-color: $card-bg;
}
.shepherd-element{
    background-color: $card-bg;
}
.shepherd-modal-overlay-container{
	&.shepherd-modal-is-visible{
		path{
			filter: invert(1);
		}
	}
}
.shepherd-arrow:before{
	background: $card-bg;
}