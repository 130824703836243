@include media-breakpoint-down(xl) {
    .sidebar {
        &.sidebar-default {
            &.sidebar-mini {
                &.sidebar-boxed {
                    transform: translateX(-130%);    
                }
                transform: translateX(-102%);
            }
        }
    }
    .sidebar-default.sidebar-mini.sidebar-boxed+.main-content,
    .sidebar-default.sidebar-boxed+.main-content,
    .sidebar-default.sidebar-mini+.main-content,
    .sidebar-default+.main-content {
        margin-left: 0;
    }
    .sidebar-default {
        &.sidebar-boxed {
            &+.main-content {
                .nav {
                    &.navbar {
                        margin: $spacer $spacer 0 $spacer;
                    }
                }
                .content-inner {
                    padding: calc(var(--bs-gutter-x, 1rem) * 2);
                }
            }
        }
    }
}

@media (min-width: 400px) and (max-width: 768px)  {
    .creadit-card-menu{
        margin-left: 19px;
    }
    
 }
 @include media-breakpoint-down(sm){
    .profile-logo{
       top: 20px;
     }
}
@media (min-width: 411px) and (max-width: 1000px) {
    .profile-logo{
        top: unset;  
    }   
}


