.is-disabled .choices__list--multiple .choices__item {
    background-color: var(--#{$variable-prefix}primary);
    border: 1px solid var(--#{$variable-prefix}primary);
}

.choices[data-type*=select-multiple] .choices__button, .choices[data-type*=text] .choices__button {
    border: 1px solid black;
}

.choices__list--multiple .choices__item {
    background-color: var(--#{$variable-prefix}primary);
    border: 1px solid var(--#{$variable-prefix}primary);
}
.choices__list--multiple .choices__item.is-highlighted {
    background-color: var(--#{$variable-prefix}primary);
    border: 1px solid var(--#{$variable-prefix}primary);
}
.choices[data-type*=select-multiple] .choices__button, .choices[data-type*=text] .choices__button {
    border: 0px;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted, 
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted{
    background-color: var(--#{$variable-prefix}primary);
    color: white;
}
.choices__list--dropdown .choices__item--selectable:active{
    color: white;
}
.choices__item--selectable:hover {
    border-color: var(--#{$variable-prefix}primary);
}
.choices__item--selectable:active {
    border-color: var(--#{$variable-prefix}primary);
}
// .choices__inner:hover {
//     border-color: var(--#{$variable-prefix}primary);
// }
.choices[data-type*=select-one]:hover {
    border-color: var(--#{$variable-prefix}primary);
}
.choices.is-disabled .choices__inner, .choices.is-disabled .choices__input {
    background-color:#f9f9f9;
}
.choices[data-type*=select-one]:active {
    border-color: var(--#{$variable-prefix}primary);
}
.choices__list--multiple .choices__item{
    margin-bottom: unset;
}

.choices__inner, .choices__input{
    background-color: $input-bg;
}
.choices__input{
    margin-bottom: unset;
}
.choices__inner,
.choices[data-type*=select-one] .choices__inner{
    padding: $input-padding-y $input-padding-x;
    border-radius: $input-border-radius;
}

.choices__list--single,
.choices__input{
    padding: 0;
}
.choices__inner{
    min-height: unset;
}
.choices__inner, .choices[data-type*=select-one] .choices__inner {
    padding-right: 3rem;
}
.choices__inner {
    border-color: $border-color;
    font-size: 1rem
}
.is-focused .choices__inner, .is-open .choices__inner {
    border-color: var(--#{$variable-prefix}primary)
}