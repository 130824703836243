@keyframes dropdownAnimation {
    from {
      opacity: 0;
      
    }
  
    to {
      opacity: 1;
    }
}

.dropdown-toggle {
    &::after {
        transition: all .15s ease;
    }
    &.show {
        transition: all .15s ease;
        &::after {
            transform: rotate(180deg)
        }
    }
}
[data-bs-toggle="dropdown"]{
    cursor: pointer;
}

.dropdown {
    .dropdown-menu {
        animation-duration: .4s;
        animation-fill-mode: both;
        backface-visibility: visible !important;
        animation-name: dropdownAnimation;
    }
    .dropdown-toggle{
        display: flex;
        align-items: center;
        &::after {
            margin-left: .5rem;
        }
    }
}
.navbar-nav {
	.dropdown-menu {
		position: absolute;
	}
}

@media all and (min-width: 992px) {
	.navbar .hover-nav {
        .dropdown-menu-end{ right:0; left: auto;  }
        .nav-item .dropdown-menu{  display:block; opacity: 0;  visibility: hidden; transition:.3s; margin-top:0;  }
        .nav-item{
            .nav-link{
                .icon{
                    transition: all 400ms ease;
                }
            }
            &:hover {
                .nav-link{
                    color: rgb(7, 6, 6);
                    .icon{
                        transition: transform 400ms ease;
                        transform: rotate(90deg);
                    }
                }
            }
        }
        .dropdown-menu.fade-down{ top:80%; transform: rotateX(-75deg); transform-origin: 0% 0%; }
        .dropdown-menu.fade-up{ top:180%;  }
        .nav-item:hover .dropdown-menu{ transition: .3s; opacity:1; visibility:visible; top:100%; transform: rotateX(0deg); }
    }
}
