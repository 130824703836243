@include media-breakpoint-up(xl) {
    .sidebar.sidebar-chat + .main-content{
        margin-right: 22rem;
        margin-left: auto;
    }
}
.iq-other-staff{
    .iq-chating-content, .iq-chating {
        margin-right: 1.25rem;
        margin-left: unset;
    }
    .iq-chating-content{
        border-radius: 0.5rem 0px 0.5rem 0.5rem;
    }
}
.iq-current-staff{
    .iq-chating-content{
        border-radius: 0px 0.5rem 0.5rem 0.5rem;
    }
}