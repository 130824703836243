.iq-timeline {
	&:before {
        background-color: $border-color;
	}
    li {
        .timeline-dots {
            background-color: $card-bg;
        }
    }
}
.iq-timeline0 {
	&:before {
        background-color: $border-color;
	}
    li {
        .timeline-dots, .timeline-dots1 {
            background-color: $card-bg;
        }
    }
}