.product-car {
 width: 100%;
 justify-content: center;
}
.product-bag {
    width: 100%;
    justify-content: center;
   }
#product-viewer360{
    &.show{
        backdrop-filter: blur(3px);
    }
}
.btnDiv{
    text-align:center;
    margin:10px 0
}
.btnDiv button{
    margin:4px 8px;
    padding:20px;
    border:0;
    background-color: var(--#{$variable-prefix}primary);
    color:#fff;
    border-radius:50%;
    font-size:16px;
    position:relative;
    cursor:pointer;
}
.btnDiv button:active{
    background-color:var(--#{$variable-prefix}primary-shade-20);
}
.btnDiv button:hover{
    box-shadow:0 0 8px 1px var(--#{$variable-prefix}primary);
}
.btnDiv button:focus{
    outline:0
}
.btnDiv button:before,
.btnDiv button.pause:after,
.btnDiv button.fast:after,
.btnDiv button.right:after,
.btnDiv button.left:after{
    position:absolute;
    content:""
}
.btnDiv button.play:before,
.btnDiv button.left:before,
.btnDiv button.right:after{
    border:11px solid transparent;
    border-left-color:inherit;
    left:16px;top:24%
}
.btnDiv button.pause:before,
.btnDiv button.pause:after{
    left:12px;
    top:28%;
    width:6px;
    height:19px;
    background:#fff
}
.btnDiv button.pause:after{
    right:12px;
    left:auto
}
.btnDiv button.stop:before{
    left:11px;
    top:25%;
    width:45%;
    height:50%;
    background:#fff
}
.btnDiv button.fast:before{
    left:17px;
    top:27%;
    background:#fff;
    width:6px;
    height:20px
}
.btnDiv button.fast:after,
.btnDiv button.slow:before{
    left: 9px;
    top: 44%;
    background: #fff;
    width: 22px;
    height: 6px;
}
.btnDiv button.right:before,
.btnDiv button.left:after{
    left:9px;
    top:44%;
    background:#fff;
    width:22px;
    height:6px
}
.btnDiv button.left:after{
    left:11px;
    width:21px
}
.btnDiv button.right:before{
    width:20px
}
.btnDiv button.left:before,
.btnDiv button.play.leftNav:before{
    border-right-color:inherit;
    border-left-color:transparent;
    left:-4px
}
.btnDiv button.right:after{
    left:22px
}
.btnDiv button.play.leftNav:before{
    left:1px;
    top:24%
}
.btnDiv.navDiv{
    text-align:left
}
.btnDiv.navDiv button{
    position:absolute;
    top:50%
}
.btnDiv.navDiv button.rightNav{
    right:25rem
}
@media(max-width:450px){
    .btnDiv.navDiv button.rightNav{
        right:0rem;
        top: 5rem;
    }
    .btnDiv.navDiv button.leftNav{
        top: 5rem;
        left: 0rem;
    }
}
