.sidebar-default {
    .navbar-nav {
        .nav-item {
            .nav-link {
                &:not(.disabled) {
                    &:hover:not(.active):not([aria-expanded=true]) {
                        background-color: rgba(var(--#{$variable-prefix}primary-rgb), .2);
                    }
                }
            }
        }
    }
}