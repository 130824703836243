/*!
* Version: 1.1.0
* Template: Hope-Ui - Responsive Bootstrap 5 Admin Dashboard Template
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for Template.
*
*/

@import "./hope-ui-design-system/helper/fonts";


// Configuration
@import "./bootstrap/functions";

// Variables
@import "./hope-ui-design-system/variable";
@import "./hope-ui-design-system/variables/index";
@import "./bootstrap/variables";

@import "./bootstrap/mixins";
@import "./bootstrap/utilities";

// Custom Mixin And Helper
@import "./hope-ui-design-system/helper/functions";
@import "./hope-ui-design-system/helper/mixins";
@import "./hope-ui-design-system/helper/reboot";

// Layout & components
@import "./hope-ui-design-system/helper/root";
@import "./bootstrap/root";
@import "./bootstrap/reboot";
@import "./bootstrap/type";
@import "./bootstrap/images";
@import "./bootstrap/containers";
@import "./bootstrap/grid";
@import "./bootstrap/tables";
@import "./bootstrap/forms";
@import "./bootstrap/buttons";
@import "./bootstrap/transitions";
@import "./bootstrap/dropdown";
@import "./bootstrap/button-group";
@import "./bootstrap/nav";
@import "./bootstrap/navbar";
@import "./bootstrap/card";
@import "./bootstrap/accordion";
@import "./bootstrap/breadcrumb";
@import "./bootstrap/pagination";
@import "./bootstrap/badge";
@import "./bootstrap/alert";
@import "./bootstrap/progress";
@import "./bootstrap/list-group";
@import "./bootstrap/close";
@import "./bootstrap/toasts";
@import "./bootstrap/modal";
@import "./bootstrap/tooltip";
@import "./bootstrap/popover";
@import "./bootstrap/carousel";
@import "./bootstrap/spinners";
@import "./bootstrap/offcanvas";

// Helpers
@import "./bootstrap/helpers";

// Utilities
@import "./bootstrap/utilities/api";

// Extra Components and Utilities and plugins
@import "./hope-ui-design-system/components/components";
@import "./hope-ui-design-system/layout-style/index";
@import "./hope-ui-design-system/helper/utilities";
@import "./hope-ui-design-system/plugins/plugins";