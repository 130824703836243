.btn-fixed-end {
    position: fixed;
    right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.btn-fixed-start {
    position: fixed;
    left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-fixed-top {
    position: fixed;
    left: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.btn-fixed-bottom {
    position: fixed;
    left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-download {
    position: fixed;
    top: 30%;
    right: -3px;
    transform: rotate(-90deg) translate(50%, -100%);
    z-index: 10;
    transform-origin: 100% 0% 0;
}